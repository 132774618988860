<template>
  <div>
    <heade></heade>
    <div
      class="com"
      :style="{
        backgroundImage: 'url(' + require('@/assets/' + backgroud) + ')',
      }"
    >
      <div class="com_box">
        <div class="con_top">
          <div class="con_top_left">
            <div class="com-left-top">
              {{ video.name }}
            </div>
            <div class="con_left_name">
              <div class="nice-scroll">
                <div
                  v-for="(item, index) in video.course_division_category"
                  :key="index"
                >
                  <div
                    v-for="(items, indexs) in item.course_division"
                    :key="indexs"
                    @click="
                      replayDivisionCourse(video.id, items.division_content_id)
                    "
                    :id="video.id"
                    :division_id="items.division_content_id"
                  >
                    <p
                      :class="
                        isActive == items.division_content_id ||
                        (indexs == 0 && queryWhere.division_id == 0)
                          ? 'division-active'
                          : 'division'
                      "
                    >
                      {{ items.name }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="con_right">
            <div class="prism-player-box">
              <vue-aliplayer-v2
                v-if="options.vid != ''"
                ref="VueAliplayerV2"
                :options="options"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <foot></foot>
  </div>
</template>

<script>
import "@css/ico.css";
import "@css/sanji.css";
import "@css/foot.css";
import $ from "jquery";
import heade from "@components/heade.vue";
import foot from "@components/foot.vue";
import VueAliplayerV2 from "vue-aliplayer-v2";
import { scroll } from "@utils/scroll";
import { getEncryptionVideo } from "@api/public";
export default {
  components: {
    heade,
    foot,
    VueAliplayerV2,
  },
  props: {},
  watch: {
    $route(to, from) {
      this.$router.go(0);
    },
  },
  data() {
    return {
      video: {},
      queryWhere: {},
      videoCateWhere: {},
      options: {
        autoplay: false,
        height: "100%",
        playsinline: true,
        vid: "",
        playauth: "",
        cover: "",
        encryptType: 1,
      },
      isActive: false,
      backgroud: "",
      path: "",
    };
  },
  mounted: function () {
    let that = this;
    that.getEncryptionVideo();
  },
  created: function () {
    this.queryWhere = this.$route.query;
    document.title = this.$route.meta.title;
    this.backgroud = this.$route.meta.background;
  },
  methods: {
    getEncryptionVideo: function () {
      let that = this;
      getEncryptionVideo(that.queryWhere).then((res) => {
        that.$set(that, "video", res.data);
        that.$set(that.options, "vid", res.data.course_vid);
        that.$set(that.options, "playauth", res.data.playauth);
        that.$set(that.videoCateWhere, "id", res.data.course_cate_id);
        if (res.data.copy != "") {
          that.$set(that.options, "cover", res.data.copy);
        }
      });
      scroll($(".nice-scroll"));
    },
    replayDivisionCourse: function (id, division_id) {
      this.queryWhere.id = id;
      this.queryWhere.division_id = division_id;
      this.isActive = division_id;
      this.options.autoplay = true;
      this.getEncryptionVideo();
      if (this.options.vid != "") {
        this.replayByVidAndPlayAuth(this.options.vid, this.options.playauth);
        this.play();
      }
    },
    replayByVidAndPlayAuth(vid, playauth) {
      this.$refs.VueAliplayerV2.replayByVidAndPlayAuth(vid, playauth);
    },
    play() {
      this.$refs.VueAliplayerV2.play();
    },
    pause() {
      this.$refs.VueAliplayerV2.pause();
    },

    replay() {
      this.$refs.VueAliplayerV2.replay();
    },
  },
  destroyed: function () {
    $(".nice-scroll").getNiceScroll().hide();
    $(".nicescroll-cursors").hide();
    $(".nicescroll-rails").remove();
  },
};
</script>

<style scoped>
.com {
  width: 100%;
  min-width: 1484px;
  height: 1220px;
  background-size: cover;
  -webkit-background-size: cover;
  -o-background-size: cover;
  background-position: center 0;
}

.com_box {
  height: auto !important;
}

.con_top {
  width: 1484px;
  height: 870px;
  margin: 0 auto;
  padding-top: 325px;
}
.con_top_left {
  position: relative;
  width: 240px;
  height: 700px;
  left: 60px;
  top: 80px;
  text-align: center;
  float: left;
}

.con_left_name {
  width: 100%;
  height: 480px;
  padding-top: 57px;
  display: flex;
  flex-direction: column;
}

.con_left_name > div > div p {
  margin: 0;
  line-height: 20px;
  text-align: center;
  font-size: 16px;
}

.con_left_name > div > div p.division-active {
  color: #c3a769 !important;
}

.con_left_name > div > div p.division {
  color: #ffffff !important;
  width: 100%;
}

.con_left_name > .nice-scroll > div > div + div {
  margin-top: 25px;
}

.con_left_name > .nice-scroll > div > div:hover {
  cursor: pointer;
}
.con_left_name::-webkit-scrollbar {
  display: none;
}

.link {
  width: 1126px;
  height: 200px;
  padding-left: 100px;
  display: flex;
  align-items: center;
}

.link .items {
  font-size: 18px;
  line-height: 29px;
  margin-right: 28px;
}
.items {
  font-weight: 600;
  font-family: "楷体";
  writing-mode: vertical-lr;
  text-align: center;
  color: #006649;
  font-size: 20px;
}
.items:hover {
  color: #e2cea0;
}

.con_right {
  position: relative;
  float: left;
  width: 974px;
  height: 73%;
  display: flex;
  flex-direction: column;
  right: 0;
  left: 150px;
  top: 110px;
}
.prism-player-box {
  position: relative;
  height: 100% !important;
  width: 100%;
  z-index: 1000;
  background-color: #000;
}
.prism-player-box .prism-player {
  height: 100% !important;
}
.nice-scroll {
  overflow-y: auto;
  height: 100%;
}
</style>
